import React from 'react';
import {Timeline, Tween} from 'react-gsap';

import "./Chipset.scss";

import chip from "../../../assets/images/chipset/chipset.svg";
import ScrollContainer from '../../utils/ScrollContainer';

const Chipset = () => {

    return <ScrollContainer height={"max-content"} id={"trigger-chipset-section"}>
        <Timeline
            target={
                <div className="chipset-section">
                    <div className="chipset-section__chip">
                        <img src={chip} alt="chipset section"/>
                    </div>
                    <div className="chipset-section__container">
                        <div className='chipset-section__text'>
                            <h2>No proprietary modems or custom chipsets needed.</h2>
                            <p>Just upload our firmware to your existing chipstack and you're globally
                                connected.</p>
                        </div>
                    </div>
                </div>
            }
            scrollTrigger={
                {
                    trigger: '#trigger-chipset-section',
                    start: 'top center',
                    end: 'bottom center',
                    scrub: true,
                    markers: false,
                }
            }
        >
            <Tween from={{autoAlpha: 0}} to={{autoAlpha: 1}} immediateRender={false}/>
            <Tween from={{autoAlpha: 1}} to={{autoAlpha: 0}} immediateRender={false}/>
        < /Timeline>
    </ScrollContainer>
}
export default Chipset;