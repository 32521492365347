import React, {Fragment} from "react";
import {Tween, Timeline} from 'react-gsap';

import "./Bluetooth.scss";

import device from "../../../assets/images/bluetooth/admin.png";
import ScrollContainer from '../../utils/ScrollContainer';

const Bluetooth = () => {
    return <ScrollContainer height={"100vh"} id={"trigger-bluetooth-section"}>
        <div className="bluetooth-section">
            <Timeline
                target={
                    <Fragment>
                        <div className="bluetooth-section__device">
                            <img src={device} alt="Bluetooth section"/>
                        </div>
                        <div className="bluetooth-section__container">
                            <div className="bluetooth-section__text">
                                <h2>Connect to space directly with Bluetooth</h2>
                                <p>
                                    Our constellation captures data directly from your Bluetooth device.{" "}
                                </p>
                            </div>
                        </div>
                    </Fragment>
                }
                scrollTrigger={
                    {
                        trigger: '#trigger-bluetooth-section',
                        start: 'top center+=200',
                        end: 'bottom center-=200',
                        scrub: true,
                        markers: false,
                    }
                }
            >
                <Tween from={{autoAlpha: 0}} to={{autoAlpha: 1}}
                       target={0} position={0}
                       immediateRender={false}
                />
                <Tween from={{autoAlpha: 1}} to={{autoAlpha: 0}}
                       target={0} position={1}
                       immediateRender={false}
                />
                <Tween
                    from={{autoAlpha: 0}} to={{autoAlpha: 1}}
                    target={1} position={0}
                    immediateRender={false}
                />
                <Tween from={{autoAlpha: 1}} to={{autoAlpha: 0}}
                       target={1} position={1}
                       immediateRender={false}
                />
            < /Timeline>
        </div>
    </ScrollContainer>
}

export default Bluetooth;
