import React, {useState, useRef, forwardRef, createRef, useEffect} from "react";
import "./Cardlist.scss";
import arrow from "../../../assets/images/cards/arrow.svg";


const CardItem = forwardRef(({
                                 icon = null,
                                 name = "",
                                 text = "",
                                 active = false,
                                 resetItem = () => {
                                 }
                             }, ref) => {

    const _onclick = () => {
        const c = !ref.current.classList.contains("card-list__item--active");
        resetItem();
        if (c)
            ref.current.classList.add("card-list__item--active")
    }
    return <div
        className={`card-list__item${active === true ? ' card-list__item--active' : ''}`}
        onClick={() => _onclick()}
        ref={ref}>
        <div className="card-list__item--inner">
            <div className="card-list__item--header">
                <span>{icon ? <img src={icon} alt={name}/> : ""}</span>
                <span>{name}</span>
                <span className="arrow">
                    <img src={arrow} alt={name}/>
                </span>
            </div>
            <div className="card-list__item--content">
                <p>{text}</p>
            </div>
        </div>
    </div>
});

const CardList = ({
                      cards = []
                  }) => {
    const [activeItem] = useState(null);
    const refs = useRef(cards.map(_ => createRef()));
    const cont = useRef(null);

    const _resetItems = () => {
        refs.current.forEach(e => e.current.classList.remove("card-list__item--active"));
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (cont.current && !cont.current.contains(event.target)) {
                _resetItems();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [cont])

    return <div className="card-list" ref={cont}>
        {cards.map((e, i) => <CardItem {...e}
                                       active={activeItem === i}
                                       resetItem={() => _resetItems()}
                                       key={`cards-item-${i}`}
                                       ref={refs.current[i]}
            />
        )}
    </div>
}

export default CardList;