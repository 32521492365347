import React, {Fragment} from "react";
import {Tween, Timeline} from 'react-gsap';

import "./TopBanner.scss";
import ScrollContainer from "../../utils/ScrollContainer";
import poster from "../../../assets/images/home/top-banner-bg.jpg";
import video from "../../../assets/videos/poster.mp4";
import Button from "../../Button";

import {useForm} from "@formspree/react";
import {FORMSPREE} from "../../../utils";

const TopBanner = (() => {
    const [state, handleSubmit] = useForm(FORMSPREE);

    return (
        <ScrollContainer height={"max-content"} id={"trigger-top-banner"}>
            <div id="top-banner" className="top-banner">
                <Timeline
                    target={
                        <Fragment>
                            <video
                                id={"top-banner__video"}
                                src={video}
                                poster={poster}
                                playsInline
                                muted
                                loop
                                autoPlay
                            />
                            <div className="top-banner__container">
                                <div className="top-banner__text">
                                    <h1>Hubble Network</h1>
                                    <h1 id="animation">
                                        powers{" "}
                                        <div className="container-animation">
                                            <span className="word w1">asset tracking.</span>
                                            <span className="word w2">wildfire detection.</span>
                                            <span className="word w3">fleet management.</span>
                                            <span className="word w4">environmental monitoring.</span>
                                            <span className="word w5">agriculture.</span>
                                            <span className="word w6">IoT.</span>
                                        </div>
                                    </h1>
                                    <p>Connecting off-the-shelf bluetooth chips to space</p>
                                    <div className="top-banner__form">
                                        {!state?.succeeded ? (
                                            <form id="home" onSubmit={handleSubmit}>
                                                <div id="home-contact" className="contact">
                                                    <input
                                                        required
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter your email to stay informed"
                                                    />
                                                    <Button type="submit">Sign Up</Button>
                                                </div>
                                            </form>
                                        ) : null}
                                        {state.succeeded ? <p>Thank you for your interest.</p> : null}
                                    </div>

                                </div>
                            </div>
                        </Fragment>
                    }
                    scrollTrigger={
                        {
                            trigger: '#trigger-top-banner',
                            start: 'top top',
                            end: 'bottom top+=100',
                            scrub: 2,
                            markers: false,
                        }
                    }
                >
                    <Tween
                        to={{
                            autoAlpha: 0,
                        }}
                        ease="linear"
                        position={0}
                    />
                </Timeline>
            </div>
        </ScrollContainer>
    );
});
export default TopBanner;

// - asset tracking
// - wildfire detection
// - fleet management
// - environmental monitoring
// - agriculture
// - IoT
