import {useForm} from "@formspree/react";
import React from "react";
import {FORMSPREE} from "../../../utils";
import Button from "../../Button";
import Footer from "../../Footer";
import "./Cta.scss";

import {Tween, Timeline} from "react-gsap";
import ScrollContainer from "../../utils/ScrollContainer";

const Cta = () => {
    const [state, handleSubmit] = useForm(FORMSPREE);

    return (
        <>
            <ScrollContainer height={"max-content"} id={"trigger-cta-section"}>
                <Timeline
                    target={
                        <div id="cta-section" className="cta-section">
                            <div className="text-box">
                                {!state?.succeeded ? (
                                    <>
                                        <p>
                                            Help deliver the next generation of IoT with a Hubble
                                            DevKit.
                                        </p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="cta-contact">
                                                <input
                                                    required
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your email to join the waitlist"
                                                />
                                                <Button>Join now</Button>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    <div className="cta-thanks">
                                        <h1>Thank you for your interest.</h1>
                                        <p>
                                            Your submission has been received. We will be
                                            contacting you via email for next steps.
                                        </p>
                                        <a href="#top-banner">
                                            <Button>Got it</Button>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    scrollTrigger={
                        {
                            trigger: '#trigger-cta-section',
                            start: 'top bottom-=300px',
                            end: 'bottom bottom',
                            scrub: true,
                            markers: false,
                        }
                    }
                >
                    <Tween
                        from={{autoAlpha: 0}}
                        to={{autoAlpha: 1}}
                        immediateRender={false}
                        ease={'power4.in'}
                    />
                </Timeline>
            </ScrollContainer>
            <Footer/>
        </>
    );
};
export default Cta;
