import React from "react";
import CardList from "../../utils/Cardlist";
import "./Cards.scss";
import {Timeline, Tween} from "react-gsap";

import ScrollContainer from "../../utils/ScrollContainer";

import i01 from "../../../assets/images/cards/i01.svg";
import i02 from "../../../assets/images/cards/i02.svg";
import i03 from "../../../assets/images/cards/i03.svg";
import i04 from "../../../assets/images/cards/i04.svg";
import i05 from "../../../assets/images/cards/i05.svg";
import i06 from "../../../assets/images/cards/i06.svg";
import i07 from "../../../assets/images/cards/i07.svg";
import i08 from "../../../assets/images/cards/i08.svg";
import i09 from "../../../assets/images/cards/i09.svg";
import i10 from "../../../assets/images/cards/i10.svg";


const cards = [
    {
        name: "Container and Pallet Tracking",
        icon: i01,
        text: "Affix a BLE chip on a weatherized sticker, slap it on the side of any container or pallet, and track your assets easily, globally, affordably.",
    },
    {
        name: "Wildfire Detection",
        icon: i02,
        text: "Integrate a temperature sensor with a BLE chip for a dime-sized unit that can send alerts from tree-lines to satellites in real-time.",
    },
    {
        name: "Energy Grid Monitoring",
        icon: i03,
        text: "Upload our firmware to your voltage and current monitor's BLE chip and receive real-time alerts when anomalies are detected.",
    },
    {
        name: "Pet, Child, and Elderly Safety",
        icon: i04,
        text: "Upload our firmware to your existing BLE location tracking device and expand your coverage globally.",
    },
    {
        name: "Heavy Equipment and Tool Tracking for Construction Sites",
        icon: i05,
        text: "Enable Hubble on your fleet of dozers to keep track of them beyond your construction site.",
    },
    {
        name: "Fleet Management and Logistics",
        icon: i06,
        text: "Replace your LoJack with a simpler, more cost-effective solution in Hubble.",
    },
    {
        name: "Predictive Maintenance",
        icon: i07,
        text: "Couple any sensor with a Hubble-enabled BLE chip and retrieve readings no matter how far from cellular coverage your assets may be.",
    },
    {
        name: "Smart Buildings",
        icon: i08,
        text: "Capturing data for water leaks, carbon monoxide buildup, and energy usage is much easier when you're not limited to your router's coverage zones.",
    },
    {
        name: "Agriculture Technology",
        icon: i09,
        text: "The proliferation of sensors across hundreds of acres without having to install costly infrastructure becomes a reality from the economies of scale made possible by Hubble.",
    },
    {
        name: "Environmental Monitoring",
        icon: i10,
        text: "Whether you're in the middle of the ocean or in the middle of a rain forest, high-fidelity environmental data can now be collected in a cost-efficient, reliable manner without the barrier-to-entry of existing infrastructure costs.",
    },
];

const Cards = () => {
    return (
        <ScrollContainer height={"max-content"} id={"trigger-cards-section"}>
            <Timeline
                target={
                    <div className="cards-section" id="use-cases">
                        <div className="cards-section__container">
                            <h2>
                                Using off-the-shelf Bluetooth chipsets, we enable IoT
                                use-cases previously impractical at global scale
                            </h2>
                            <CardList cards={cards}/>
                        </div>
                    </div>
                }
                scrollTrigger={
                    {
                        trigger: '#trigger-cards-section',
                        start: 'top center+=200',
                        endTrigger: '#trigger-cta-section',
                        end: 'bottom bottom',
                        scrub: true,
                        markers: false,
                    }
                }
            >
                <Tween
                    from={{autoAlpha: 0, y: "0rem"}}
                    to={{autoAlpha: 1, y: "10rem"}}
                    immediateRender={false}
                    ease="power1.easeIn"
                />
                <Tween
                    from={{autoAlpha: 1, y: "10rem"}}
                    to={{autoAlpha: 0, y: "-5rem"}}
                    immediateRender={false}
                    ease="power1.easeIn"
                />
            </Timeline>
        </ScrollContainer>
    );
};

export default Cards;
