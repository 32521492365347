/* eslint-disable import/no-anonymous-default-export */
import React from "react";

// sections
import TopBanner from "../components/sections/TopBanner";
import Chipset from "../components/sections/Chipset";
import Bluetooth from "../components/sections/Bluetooth";
import Cards from "../components/sections/Cards";
import Cta from "../components/sections/CTA/Cta";

const Home = () => (
    <>
        <TopBanner/>
        <Chipset/>
        <Bluetooth/>
        <Cards/>
        <Cta/>
    </>
);
export default Home;
