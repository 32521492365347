import Footer from "../components/Footer";

const Privacy = () => {
  return (<>
    <section id="TERMS" className="terms">
      <div className="terms__container">
        <h1 dir="ltr">
            Privacy Policy
        </h1>
        <p dir="ltr">
            Last updated: March 04, 2022
        </p>
        <p dir="ltr">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the Service
            and tells You about Your privacy rights and how the law protects You.
        </p>
        <p dir="ltr">
            We use Your Personal data to provide and improve the Service. By using the
            Service, You agree to the collection and use of information in accordance
            with this Privacy Policy. This Privacy Policy has been created with the
            help of the <a href="https://www.termsfeed.com/blog/sample-privacy-policy-template/">
                Privacy Policy Template
            </a>.
        </p>
        <p dir="ltr">
            Interpretation and Definitions
        </p>
        <br/>
        <h2 dir="ltr">
            Interpretation
        </h2>
        <p dir="ltr">
            The words of which the initial letter is capitalized have meanings defined
            under the following conditions. The following definitions shall have the
            same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h2 dir="ltr">
            Definitions
        </h2>
        <p dir="ltr">
            For the purposes of this Privacy Policy:
        </p>
        <ol>
            <li dir="ltr">
                    Account means a unique account created for You to access our
                    Service or parts of our Service.
            </li>
            <li dir="ltr">
                    Company (referred to as either "the Company", "We", "Us" or "Our"
                    in this Agreement) refers to Hubble Network LLC, Nevada, USA.
            </li>
            <li dir="ltr">
                    Cookies are small files that are placed on Your computer, mobile
                    device or any other device by a website, containing the details of
                    Your browsing history on that website among its many uses.
            </li>
            <li dir="ltr">
                    Country refers to: Nevada, United States
            </li>
            <li dir="ltr">
                    Device means any device that can access the Service such as a
                    computer, a cellphone or a digital tablet.
            </li>
            <li dir="ltr">
                    Personal Data is any information that relates to an identified or
                    identifiable individual.
            </li>
            <li dir="ltr">
                    Service refers to the Website.
            </li>
            <li dir="ltr">
                    Service Provider means any natural or legal person who processes
                    the data on behalf of the Company. It refers to third-party
                    companies or individuals employed by the Company to facilitate the
                    Service, to provide the Service on behalf of the Company, to
                    perform services related to the Service or to assist the Company in
                    analyzing how the Service is used.
            </li>
            <li dir="ltr">
                    Usage Data refers to data collected automatically, either generated
                    by the use of the Service or from the Service infrastructure itself
                    (for example, the duration of a page visit).
            </li>
            <li dir="ltr">
                    Website refers to Hubble Network, accessible from <a href="https://www.hubblenetwork.com">
                        https://www.hubblenetwork.com
                    </a>
            </li>
            <li dir="ltr">
                    You means the individual accessing or using the Service, or the
                    company, or other legal entity on behalf of which such individual
                    is accessing or using the Service, as applicable.
            </li>
        </ol>
        <h2>
            Collecting and Using Your Personal Data
        </h2>
        <h3 dir="ltr">
            Types of Data Collected
        </h3>
        <h4 dir="ltr">
            Personal Data
        </h4>
        <p dir="ltr">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or identify
            You. Personally identifiable information may include, but is not limited
            to:
        </p>
        <p dir="ltr">
            Email address
        </p>
        <p dir="ltr">
            Usage Data
        </p>
        <h3 dir="ltr">
            Usage Data
        </h3>
        <p dir="ltr">
            Usage Data is collected automatically when using the Service.
        </p>
        <p dir="ltr">
            Usage Data may include information such as Your Device's Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages of our
            Service that You visit, the time and date of Your visit, the time spent on
            those pages, unique device identifiers and other diagnostic data.
        </p>
        <p dir="ltr">
            When You access the Service by or through a mobile device, We may collect
            certain information automatically, including, but not limited to, the type
            of mobile device You use, Your mobile device unique ID, the IP address of
            Your mobile device, Your mobile operating system, the type of mobile
            Internet browser You use, unique device identifiers and other diagnostic
            data.
        </p>
        <p dir="ltr">
            We may also collect information that Your browser sends whenever You visit
            our Service or when You access the Service by or through a mobile device.
        </p>
        <h3 dir="ltr">
            Tracking Technologies and Cookies
        </h3>
        <p dir="ltr">
            We use Cookies and similar tracking technologies to track the activity on
            Our Service and store certain information. Tracking technologies used are
            beacons, tags, and scripts to collect and track information and to improve
            and analyze Our Service. The technologies We use may include:
        </p>
        <ul>
            <li dir="ltr">
                    Cookies or Browser Cookies. A cookie is a small file placed on Your
                    Device. You can instruct Your browser to refuse all Cookies or to
                    indicate when a Cookie is being sent. However, if You do not accept
                    Cookies, You may not be able to use some parts of our Service.
                    Unless you have adjusted Your browser setting so that it will
                    refuse Cookies, our Service may use Cookies.
            </li>
            <li dir="ltr">
                    Flash Cookies. Certain features of our Service may use local stored
                    objects (or Flash Cookies) to collect and store information about
                    Your preferences or Your activity on our Service. Flash Cookies are
                    not managed by the same browser settings as those used for Browser
                    Cookies. For more information on how You can delete Flash Cookies,
                    please read "Where can I change the settings for disabling, or
                    deleting local shared objects?" available at <a
                        href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                    >
                        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                    </a>
            </li>
            <li dir="ltr">
                    Web Beacons. Certain sections of our Service and our emails may
                    contain small electronic files known as web beacons (also referred
                    to as clear gifs, pixel tags, and single-pixel gifs) that permit
                    the Company, for example, to count users who have visited those
                    pages or opened an email and for other related website statistics
                    (for example, recording the popularity of a certain section and
                    verifying system and server integrity).
            </li>
        </ul>
        <p dir="ltr">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain
            on Your personal computer or mobile device when You go offline, while
            Session Cookies are deleted as soon as You close Your web browser. You can
            learn more about cookies here: <a href="https://www.termsfeed.com/privacy-policy-generator/#faq-8">
                Cookies by TermsFeed Generator
            </a>
            .
        </p>
        <p dir="ltr">
            We use both Session and Persistent Cookies for the purposes set out below:
        </p>
        <ol>
            <li dir="ltr">
                    <b>Necessary / Essential Cookies</b>
            </li>
            <ol>
                <li dir="ltr">
                        <b>Type:</b> Session Cookies
                </li>
                <li dir="ltr">
                        <b>Administered by:</b> Us
                </li>
                <li dir="ltr">
                        <b>Purpose:</b> These Cookies are essential to provide You with
                        services available through the Website and to enable You to use
                        some of its features. They help to authenticate users and
                        prevent fraudulent use of user accounts. Without these Cookies,
                        the services that You have asked for cannot be provided, and We
                        only use these Cookies to provide You with those services.
                </li>
            </ol>
            <li dir="ltr">
                <b> Cookies Policy / Notice Acceptance Cookies</b>
            </li>
            <ol>
                <li dir="ltr">
                        <b>Type:</b> Persistent Cookies
                </li>
                <li dir="ltr">
                        <b>Administered by:</b> Us
                </li>
                <li dir="ltr">
                        <b>Purpose:</b> These Cookies identify if users have accepted the use
                        of cookies on the Website.
                </li>
            </ol>
            <li dir="ltr">
                    <b>Functionality Cookies</b>
            </li>
            <ol>
                <li dir="ltr">
                        <b>Type:</b> Persistent Cookies
                </li>
                <li dir="ltr">
                        <b>Administered by:</b> Us
                </li>
                <li dir="ltr">
                        <b>Purpose:</b> These Cookies allow us to remember choices You make
                        when You use the Website, such as remembering your login
                        details or language preference. The purpose of these Cookies is
                        to provide You with a more personal experience and to avoid You
                        having to re-enter your preferences every time You use the
                        Website.
                </li>
            </ol>
        </ol>
        <p dir="ltr">
            For more information about the cookies we use and your choices regarding
            cookies, please visit our Cookies Policy or the Cookies section of our
            Privacy Policy.
        </p>
        <h2 dir="ltr">
            Use of Your Personal Data
        </h2>
        <p dir="ltr">
            The Company may use Personal Data for the following purposes:
        </p>
        <p dir="ltr">
            To provide and maintain our Service, including to monitor the usage of our
            Service.
        </p>
        <p dir="ltr">
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to different
            functionalities of the Service that are available to You as a registered
            user.
        </p>
        <p dir="ltr">
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or services
            You have purchased or of any other contract with Us through the Service.
        </p>
        <p dir="ltr">
            To contact You: To contact You by email, telephone calls, SMS, or other
            equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products or contracted
            services, including the security updates, when necessary or reasonable for
            their implementation.
        </p>
        <p dir="ltr">
            To provide You with news, special offers and general information about
            other goods, services and events which we offer that are similar to those
            that you have already purchased or enquired about unless You have opted not
            to receive such information.
        </p>
        <p dir="ltr">
            To manage Your requests: To attend and manage Your requests to Us.
        </p>
        <p dir="ltr">
            For business transfers: We may use Your information to evaluate or conduct
            a merger, divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going concern
            or as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by Us about our Service users is among the assets
            transferred.
        </p>
        <p dir="ltr">
            For other purposes: We may use Your information for other purposes, such as
            data analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
        </p>
        <p dir="ltr">
            We may share Your personal information in the following situations:
        </p>
        <ul>
            <li dir="ltr">
                    <b>With Service Providers:</b> We may share Your personal information with
                    Service Providers to monitor and analyze the use of our Service, to
                    contact You.
            </li>
            <li dir="ltr">
                    <b>For business transfers:</b> We may share or transfer Your personal
                    information in connection with, or during negotiations of, any
                    merger, sale of Company assets, financing, or acquisition of all or
                    a portion of Our business to another company.
            </li>
            <li dir="ltr">
                    <b>With Affiliates:</b> We may share Your information with Our affiliates,
                    in which case we will require those affiliates to honor this
                    Privacy Policy. Affiliates include Our parent company and any other
                    subsidiaries, joint venture partners or other companies that We
                    control or that are under common control with Us.
            </li>
            <li dir="ltr">
                    <b>With business partners:</b> We may share Your information with Our
                    business partners to offer You certain products, services or
                    promotions.
            </li>
            <li dir="ltr">
                    <b>With other users:</b> when You share personal information or otherwise
                    interact in the public areas with other users, such information may
                    be viewed by all users and may be publicly distributed outside.
            </li>
            <li dir="ltr">
                    <b>With Your consent:</b> We may disclose Your personal information for
                    any other purpose with Your consent.
            </li>
        </ul>
        <h2 dir="ltr">
            Retention of Your Personal Data
        </h2>
        <p dir="ltr">
            The Company will retain Your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and use
            Your Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply
            with applicable laws), resolve disputes, and enforce our legal agreements
            and policies.
        </p>
        <p dir="ltr">
            The Company will also retain Usage Data for internal analysis purposes.
            Usage Data is generally retained for a shorter period of time, except when
            this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this
            data for longer time periods.
        </p>
        <h2 dir="ltr">
            Transfer of Your Personal Data
        </h2>
        <p dir="ltr">
            Your information, including Personal Data, is processed at the Company's
            operating offices and in any other places where the parties involved in the
            processing are located. It means that this information may be transferred
            to — and maintained on — computers located outside of Your state, province,
            country or other governmental jurisdiction where the data protection laws
            may differ than those from Your jurisdiction.
        </p>
        <p dir="ltr">
            Your consent to this Privacy Policy followed by Your submission of such
            information represents Your agreement to that transfer.
        </p>
        <p dir="ltr">
            The Company will take all steps reasonably necessary to ensure that Your
            data is treated securely and in accordance with this Privacy Policy and no
            transfer of Your Personal Data will take place to an organization or a
            country unless there are adequate controls in place including the security
            of Your data and other personal information.
        </p>
        <h2 dir="ltr">
            Disclosure of Your Personal Data
        </h2>
        <h3 dir="ltr">
            Business Transactions
        </h3>
        <p dir="ltr">
            If the Company is involved in a merger, acquisition or asset sale, Your
            Personal Data may be transferred. We will provide notice before Your
            Personal Data is transferred and becomes subject to a different Privacy
            Policy.
        </p>
        <h3 dir="ltr">
            Law enforcement
        </h3>
        <p dir="ltr">
            Under certain circumstances, the Company may be required to disclose Your
            Personal Data if required to do so by law or in response to valid requests
            by public authorities (e.g. a court or a government agency).
        </p>
        <h3 dir="ltr">
            Other legal requirements
        </h3>
        <p dir="ltr">
            The Company may disclose Your Personal Data in the good faith belief that
            such action is necessary to:
        </p>
        <ul>
            <li dir="ltr">
                    Comply with a legal obligation
            </li>
            <li dir="ltr">
                    Protect and defend the rights or property of the Company
            </li>
            <li dir="ltr">
                    Prevent or investigate possible wrongdoing in connection with the
                    Service
            </li>
            <li dir="ltr">
                    Protect the personal safety of Users of the Service or the public
            </li>
            <li dir="ltr">
                    Protect against legal liability
            </li>
        </ul>
        <h2 dir="ltr">
            Security of Your Personal Data
        </h2>
        <p dir="ltr">
            The security of Your Personal Data is important to Us, but remember that no
            method of transmission over the Internet, or method of electronic storage
            is 100% secure. While We strive to use commercially acceptable means to
            protect Your Personal Data, We cannot guarantee its absolute security.
        </p>
        <h3 dir="ltr">
            Children's Privacy
        </h3>
        <p dir="ltr">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone under the
            age of 13. If You are a parent or guardian and You are aware that Your
            child has provided Us with Personal Data, please contact Us. If We become
            aware that We have collected Personal Data from anyone under the age of 13
            without verification of parental consent, We take steps to remove that
            information from Our servers.
        </p>
        <p dir="ltr">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may require
            Your parent's consent before We collect and use that information.
        </p>
        <h3 dir="ltr">
            Links to Other Websites
        </h3>
        <p dir="ltr">
            Our Service may contain links to other websites that are not operated by
            Us. If You click on a third party link, You will be directed to that third
            party's site. We strongly advise You to review the Privacy Policy of every
            site You visit.
        </p>
        <p dir="ltr">
            We have no control over and assume no responsibility for the content,
            privacy policies or practices of any third party sites or services.
        </p>
        <h3 dir="ltr">
            Changes to this Privacy Policy
        </h3>
        <p dir="ltr">
            We may update Our Privacy Policy from time to time. We will notify You of
            any changes by posting the new Privacy Policy on this page.
        </p>
        <p dir="ltr">
            We will let You know via email and/or a prominent notice on Our Service,
            prior to the change becoming effective and update the "Last updated" date
            at the top of this Privacy Policy.
        </p>
        <p dir="ltr">
            You are advised to review this Privacy Policy periodically for any changes.
            Changes to this Privacy Policy are effective when they are posted on this
            page.
        </p>
        <h2 dir="ltr">
            Contact Us
        </h2>
        <p dir="ltr">
            If you have any questions about this Privacy Policy, You can contact us:
        </p>
        <ul>
            <li dir="ltr">
                <p dir="ltr">
                    By email: <a href="mailto:info@hubblenetwork.com">info@hubblenetwork.com</a>
                </p>
            </li>
        </ul>
      </div>
    </section>
    <Footer />
  </>
  );
};
export default Privacy;
